import EntityForm from "@/mixins/EntityForm";
import Component, { mixins } from "vue-class-component";

@Component({
  inject: {
    eventService: "eventService",
  },
})
export default class AreaForm extends mixins(EntityForm) {
  model = {};
  internal_events = [];
  isEventInsertable = false

  get elements() {
    return [
      {
        id: "idEvent",
        label: this.translations.labels.events,
        type: "select",
        items: this.events,
        required: true,
        returnObject: false,
        readonly: !this.isEventInsertable
      },
      {
        id: "code",
        label: this.translations.labels.hall_form_code,
        type: "text",
        required: true,
        rules: "min:4",
        onAction: this.noSpaces,
      }
    ];
  }
  noSpaces(evt) {
    let charCode = evt.charCode;
    if (charCode === 32) evt.preventDefault();
    return true;
  }

  get event() {
    const _event = this.internal_events.filter(
      (e) => {
        return e.id == this.model.idEvent;
      }
    );
    if (_event.length > 0) {
      return _event[0];
    }
    return null;
  }
  get events() {
    return this.internal_events.map((i) => {
      return {
        languages: i.languages,
        label: i.code,
        value: i.id,
      };
    });
  }

  get helpLink() {
    return this.translations.pageHelp.area;
  }

  // eslint-disable-next-line no-empty-function
  afterCreate() {
  }

  async created() {
    this.internal_events = await this.eventService.list();
    this.model.event = this.events.filter(
      (e) => e.value == this.model.idEvent
    )[0];

    this.afterCreate();
  }
}
