<script>
import AreaForm from "@/views/menu/area/areaForm";
import Component, { mixins } from "vue-class-component";

@Component({
  inject: {
    areaService: "areaService",
    toastService: "toastService",
  },
})
export default class AreaNew extends mixins(AreaForm) {
  formId = "new-area-form";

  get fieldColumns() {
    return 2;
  }
  get actions() {
    return [
      {
        id: "save",
        type: "submit",
        text: this.translations.labels.button_save,
        leftIcon: "mdi-content-save-outline",
        handle: this.save,
      },
    ];
  }
  async save(action, passesValidation) {
    this.errors = [];
    if (!passesValidation) {
      return;
    }
    try {
      await this.areaService.create({
        idEvent: this.model.idEvent,
        code: this.model.code,
        order: this.model.order,
      });
      this.$router.push("/area/list");
      this.toastService.success(this.translations.success.area_create);
    } catch (error) {
      this.errors = this.formatApiErrors(error);
    }
  }

  getFormTitle() {
    return this.translations.pageTitles.areas_new;
  }

  afterCreate () {
    this.model = {
      idEvent: "",
      code: "",
      order: 0,
    };

    this.isEventInsertable = true;
    this.isReady = true;
  }
}
</script>
